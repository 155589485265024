<template>
  <b-card>
    <div
      class="mt-4 mb-2"
    >
      <!-- table -->
      <vue-good-table
        ref="my-table"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionText: 'cliente(s) seleccionado(s)',
          clearSelectionText: selectedRows.length == rows.length ? 'Deseleccionar todos' : 'Seleccionar todos',
          selectionInfoClass: 's-box' }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        @on-select-all="selectAll"
        @on-selected-rows-change="selectionChanged"
      >
        <div
          slot="emptystate"
          class="text-center my-5"
        >
          <h3
            v-if="clients.length>0"
          >
            Su búsqueda no coincide
          </h3>
          <b-overlay
            id="overlay-background"
            :show="show"
            rounded="lg"
            variant="bg-light"
            opacity="1.00"
            no-wrap
            no-fade
            class="mt-5"
          />
          <h3
            v-if="!show && clients.length==0"
          >
            Aún no han registrado clientes
          </h3>
        </div>
        <div slot="table-actions">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="exportClients"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            <span class="align-middle">Exportar clientes</span>
          </b-button>
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span :id="`tooltip-button-one-${props.row._id}`">
              <b-button
                v-b-modal.modal-efideData
                :variant="props.row.efideData ? 'primary' : 'danger'"
                class="btn-icon"
                block
                @click="setEfideData(props.row)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-tooltip
                :target="`tooltip-button-one-${props.row._id}`"
                triggers="hover"
                boundary-padding="10"
              >
                Editar datos
              </b-tooltip>
            </span>
            <span :id="`tooltip-tip-two-${props.row._id}`">
              <b-button
                v-b-modal.modal-password
                variant="light"
                class="btn-icon"
                block
                @click="setEfideData(props.row)"
              >
                <feather-icon icon="LockIcon" />
              </b-button>
              <b-tooltip
                :target="`tooltip-tip-two-${props.row._id}`"
                triggers="hover"
                boundary-padding="10"
              >
                Editar contraseña
              </b-tooltip>
            </span>
          </span>
          <!-- Column: Common -->
          <span
            v-else
            :id="'user-row-' + props.row._id"
          >
            {{ props.formattedRow[props.column.field] || "-" }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mostrando grupos de
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap "> de {{ props.total }} registro(s) </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
        <div
          slot="selected-row-actions"
          class="container-buttons-bills row align-items-center"
        >
          <b-form-group
            label="Fecha de actualización"
            label-for="updateDate"
          >
            <b-form-input
              id="updateDate"
              v-model="updateDate"
              name="updateDate"
              disabled
            />
          </b-form-group>
          <b-col>
            <b-row
              class="d-flex justify-content-center mx-1"
            >
              <b-button
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-htmlEmail
                variant="primary"
              >
                Enviar correo masivo
              </b-button>
            </b-row>
          </b-col>
        </div>
      </vue-good-table>
      <!-- modal reject -->
      <b-modal
        id="modal-htmlEmail"
        centered
        title="Mensaje personalizado"
        cancel-variant="outline-secondary"
        cancel-title="Cancelar"
        ok-title="Enviar"
        @ok="handleEmail"
        @cancel="cleanSendEmailInputs"
      >
        <validation-observer ref="emailForm">
          <b-form
            @submit.prevent="validateEmailForm"
          >
            <b-form-group
              label="Asunto*"
              label-for="subject"
            >
              <validation-provider
                #default="{ errors }"
                name="asunto"
                rules="max:50|required"
                vid="subject"
              >
                <b-form-input
                  id="subject"
                  v-model="subject"
                  :state="errors.length > 0 ? false:null"
                  name="subject"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Descripción*"
              label-for="description"
            >
              <validation-provider
                #default="{ errors }"
                name="descripción"
                vid="description"
                rules="required"
              >
                <quill-editor
                  id="description"
                  v-model="description"
                  :state="errors.length > 0 ? false:null"
                  name="description"
                  class="ql-custom"
                  :options="editorOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <small
              v-if="selected"
              class="text-warning"
            >
              * Recuerde añadir el link de redirección si es necesario
            </small>
            <b-form-checkbox
              v-model="selected"
              name="check-button"
              switch
              inline
            >
              Solicitar actualización de perfil
            </b-form-checkbox>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- modal reject -->
      <b-modal
        id="modal-efideData"
        centered
        title="Datos de efide"
        cancel-variant="outline-secondary"
        cancel-title="Cancelar"
        ok-title="Enviar"
        @ok="handleEfideData"
        @cancel="cleanSendEfideDataInputs"
      >
        <validation-observer ref="efideDataForm">
          <b-form
            @submit.prevent="validateEfideDataForm"
          >
            <h5><b>R.U.C: </b> {{ clientSelected ? clientSelected.ruc : '' }} </h5>
            <h5><b>Razón social o nombre: </b> {{ clientSelected ? clientSelected.businessName : '' }} </h5>
            <br>
            <b-form-group
              label="R.U.C"
              label-for="ruc"
            >
              <validation-provider
                #default="{ errors }"
                name="ruc"
                rules="required|min:11|ruc"
                vid="ruc"
              >
                <b-input-group>
                  <b-form-input
                    id="ruc"
                    v-model="clientRuc"
                    :state="errors.length > 0 ? false:null"
                    name="ruc"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      size="sm"
                      :disabled="errors.length > 0"
                      @click="sendChangeRuc()"
                    >
                      <feather-icon icon="UploadIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="efideData ? Object.keys(efideData)[0] : '-'"
              :label-for="efideData ? Object.keys(efideData)[0] : '-'"
            >
              <validation-provider
                #default="{ errors }"
                :name="efideData ? Object.keys(efideData)[0] : '-'"
                rules="required"
                :vid="efideData ? Object.keys(efideData)[0] : '-'"
              >
                <b-form-input
                  :id="efideData ? Object.keys(efideData)[0] : '-'"
                  v-model="efideData[Object.keys(efideData)[0]]"
                  :state="errors.length > 0 ? false:null"
                  :name="efideData ? Object.keys(efideData)[0] : '-'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="efideData ? Object.keys(efideData)[1] : '-'"
              :label-for="efideData ? Object.keys(efideData)[1] : '-'"
            >
              <validation-provider
                #default="{ errors }"
                :name="efideData ? Object.keys(efideData)[1] : '-'"
                rules="required"
                :vid="efideData ? Object.keys(efideData)[1] : '-'"
              >
                <b-form-input
                  :id="efideData ? Object.keys(efideData)[1] : '-'"
                  v-model="efideData[Object.keys(efideData)[1]]"
                  :state="errors.length > 0 ? false:null"
                  :name="efideData ? Object.keys(efideData)[1] : '-'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="efideData ? Object.keys(efideData)[2] : '-'"
              :label-for="efideData ? Object.keys(efideData)[2] : '-'"
            >
              <validation-provider
                #default="{ errors }"
                :name="efideData ? Object.keys(efideData)[2] : '-'"
                rules="required"
                :vid="efideData ? Object.keys(efideData)[2] : '-'"
              >
                <b-form-input
                  :id="efideData ? Object.keys(efideData)[2] : '-'"
                  v-model="efideData[Object.keys(efideData)[2]]"
                  :state="errors.length > 0 ? false:null"
                  :name="efideData ? Object.keys(efideData)[2] : '-'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- modal reject -->
      <b-modal
        id="modal-password"
        centered
        title="Cambio de contraseña"
        cancel-variant="outline-secondary"
        cancel-title="Cancelar"
        ok-title="Cambiar contraseña"
        @ok="handleChangePassword"
        @cancel="cleanChangePasswordInputs"
      >
        <validation-observer ref="simpleRules">
          <b-form
            @submit.prevent="validateChangePasswordForm"
          >
            <h5><b>R.U.C: </b> {{ clientSelected ? clientSelected.ruc : '' }} </h5>
            <h5><b>Razón social o nombre: </b> {{ clientSelected ? clientSelected.businessName : '' }} </h5>
            <h5><b>ClientId: </b> {{ efideData ? efideData.clientId : '' }} </h5>
            <br>
            <!-- password -->
            <b-form-group
              label="Nueva contraseña"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="nueva contraseña"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirmar nueva contraseña"
            >
              <validation-provider
                #default="{ errors }"
                name="confirmar nueva contraseña"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </b-card>
</template>

<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BInputGroup, BInputGroupAppend, BPagination, VBTooltip, BFormSelect, BCard, BOverlay, BRow, BCol, BButton, VBModal, BFormGroup, BForm, BFormInput, BFormCheckbox, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import * as XLSX from 'xlsx'
// const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BTooltip,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    quillEditor,
    ValidationObserver,
    ValidationProvider,
    BInputGroup,
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      // Toggle Password
      cPassword: '',
      password: '',
      clientRuc: '',
      password1FieldType: 'password',
      password2FieldType: 'password',
      required,
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['link'],
              ['clean'],
            ],
          },
        },
      },
      show: true,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'R.U.C',
          field: 'ruc',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar R.U.C',
          },
        },
        {
          label: 'Fecha de registro',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar f. registro',
          },
          tdClass: 'text-center',
        },
        {
          label: 'Fecha de actualización',
          field: 'updatedAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          formatFn: this.dateFormat,
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar f. actualización',
          },
          tdClass: 'text-center',
        },
        {
          label: 'Razón social o nombre',
          field: 'businessName',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar razón social',
          },
        },
        {
          label: 'Correo de contacto',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar correo',
          },
        },
        {
          label: 'Línea aprobada',
          field: 'payingDate',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar l. aprobada',
          },
        },
        {
          label: 'Opción',
          field: 'action',
        },
      ],
      rows: [],
      clients: [],
      selectedRows: [],
      selected: false,
      flag: false,
      searchTerm: '',
      subject: '',
      description: '',
      updateDate: '',
      clientSelected: null,
      efideData: null,
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        current      : 'light-primary',
        approved : 'light-success',
        rejected     : 'light-danger',
        notApproved     : 'light-warning',
        applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    useJwt.getClients({
      email: this.userEmail,
    }).then(res => {
      let values = []
      values = res.data.clients.length > 0 ? res.data.clients : []
      if (values.length > 0) {
        for (let i = 0; i < values.length; i += 1) {
          if (values[i].clientType === 0) {
            values[i].businessName = values[i].names
          }
          values[i].createdAt = this.normalizeDate(values[i].createdAt)
          values[i].updatedAt = this.normalizeDate(values[i].updatedAt)
        }
      }
      this.rows = values.length > 0 ? values : []
      this.clients = this.rows
      this.show = false
    })
    const today = new Date()
    const year = today.getFullYear()
    const firstClue = today.getMonth() <= 6 && today.getDate() <= 28
    const secondClue = today.getMonth() === 11 && today.getDate() > 24
    this.updateDate = firstClue || secondClue ? `28/07/${year}` : `24/12/${year}`
  },
  methods: {
    exportClients() {
      const dataExcel = []
      for (let index = 0; index < this.clients.length; index += 1) {
        dataExcel.push({
          email: this.clients[index].email || '-',
          ruc: this.clients[index].ruc || '-',
          businessName: this.clients[index].businessName || '-',
          names: this.clients[index].names || '-',
          role: this.clients[index].role || '-',
          tel: this.clients[index].tel || '-',
          registerComplete: this.clients[index].registerComplete || '-',
          editEnabled: this.clients[index].editEnabled || '-',
          createdAt: this.clients[index].createdAt || '-',
          updatedAt: this.clients[index].updatedAt || '-',
        })
      }
      const data = XLSX.utils.json_to_sheet(dataExcel, {
        header: ['ruc', 'email', 'businessName', 'names', 'role', 'tel', 'registerComplete', 'editEnabled', 'createdAt', 'updatedAt'],
      })
      /* eslint-disable dot-notation */
      data['A1'].v = 'R.U.C'
      data['B1'].v = 'Correo electrónico'
      data['C1'].v = 'Razón social o nombre'
      data['D1'].v = 'Nombre'
      data['E1'].v = 'Rol'
      data['F1'].v = 'Número de contacto'
      data['G1'].v = 'Registro completo'
      data['H1'].v = 'Editar habilitado'
      data['I1'].v = 'Fecha de registro'
      data['J1'].v = 'Fecha de actualización'
      const workbook = XLSX.utils.book_new()
      const filename = 'Clientes'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    setEfideData(client) {
      this.clientSelected = null
      this.efideData = null
      this.clientRuc = ''
      this.cPassword = ''
      this.password = ''
      this.clientSelected = client
      this.clientRuc = client.ruc
      this.efideData = client.efideData
      if (this.efideData) {
        switch (this.clientSelected.clientType) {
          case 1:
            this.efideData = {
              EmpresaId: this.efideData.EmpresaId || '',
              Status: this.efideData.Status || 'A',
              clientId: this.efideData.clientId || '',
            }
            break
          case 0:
            this.efideData = {
              PersonaId: this.efideData.PersonaId || '',
              Status: this.efideData.Status || 'A',
              clientId: this.efideData.clientId || '',
            }
            break
          default:
            break
        }
      } else {
        switch (this.clientSelected.clientType) {
          case 1:
            this.efideData = {
              EmpresaId: '',
              Status: 'A',
              clientId: '',
            }
            break
          case 0:
            this.efideData = {
              PersonaId: '',
              Status: 'A',
              clientId: '',
            }
            break
          default:
            break
        }
      }
    },
    dateFormat(value) {
      return value ? value.split('-').reverse().join('/') : '-'
    },
    moneyFormat(money) {
      return !Number.isNaN(Number.parseFloat(money)) ? Number.parseFloat(money).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-'
    },
    normalizeDate(date) {
      const newDate = new Date(date)
      newDate.setTime(newDate.getTime() + 5 * 60 * 60 * 1000)
      const dateArr = []
      dateArr.push(newDate.getFullYear().toString(), (newDate.getMonth() + 1).toString().padStart(2, '0'), newDate.getDate().toString().padStart(2, '0'))
      return dateArr.join('-')
    },
    sendEmail() {
      const selected = this.selectedRows.map(({ email }) => email)
      useJwt.sendMasiveEmail({
        email: this.userEmail,
        emails: selected,
        subject: this.subject,
        description: this.description,
        isEdit: this.selected,
      })
        .then(res => {
          const values = res.data.clients
          values.forEach(e => {
            if (e.clientType === 0) {
              e.businessName = e.names
            }
            const updatedAt = e.updatedAt ? e.updatedAt.split('T')[0] : '-'
            const createdAt = e.createdAt ? e.createdAt.split('T')[0] : '-'
            e.updatedAt = updatedAt
            e.createdAt = createdAt
          })
          this.rows = values
          this.clients = this.rows
          this.$bvModal.hide('modal-htmlEmail')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Éxito',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Se ha(n) enviado las correos con éxito.',
              autoHideDelay: 8000,
            },
          })
        })
        .catch(error => {
          console.log('errores', error.response.data.error)
        })
    },
    sendEfideData() {
      useJwt.sendEfideData({
        email: this.userEmail,
        userId: this.clientSelected._id,
        ruc: this.clientRuc,
        efideData: this.efideData,
      })
        .then(res => {
          const index = this.clients.findIndex(e => e._id === this.clientSelected._id)
          this.clients[index].ruc = res.data.ruc
          this.clients[index].efideData = res.data.efideData
          this.clients[index].canEdit = true
          this.clients[index].registerComplete = true
          this.clients[index].updatedAt = this.normalizeDate(res.data.updatedAt)
          this.rows = this.clients
          this.$bvModal.hide('modal-efideData')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Éxito',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Se ha actualizado los datos del cliente con éxito.',
              autoHideDelay: 8000,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error.response.data.error,
              autoHideDelay: 8000,
            },
          })
          console.log('errores', error.response.data.error)
        })
    },
    sendChangePassword() {
      useJwt.postResetByAdmin({
        clientEmail: this.clientSelected.email,
        adminEmail: this.userEmail,
        clientId: this.clientSelected._id,
        resetPassword: this.cPassword,
        resetPasswordRepeat: this.password,
      })
        .then(() => {
          this.$bvModal.hide('modal-password')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Éxito',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Se ha actualizado la contraseña del cliente con éxito.',
              autoHideDelay: 8000,
            },
          })
        })
        .catch(error => {
          console.log('errores', error.response.data.error)
        })
    },
    sendChangeRuc() {
      useJwt.postRucByAdmin({
        email: this.userEmail,
        clientId: this.clientSelected._id,
        ruc: this.clientRuc,
      })
        .then(() => {
          const index = this.clients.findIndex(e => e._id === this.clientSelected._id)
          this.clientSelected.ruc = this.clientRuc
          this.clients[index].ruc = this.clientRuc
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Éxito',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Se ha actualizado el R.U.C del cliente con éxito.',
              autoHideDelay: 8000,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'El R.U.C ingresado ya se encuentra registrado.',
              autoHideDelay: 8000,
            },
          })
          console.log('errores', error.response.data.error)
        })
    },
    selectionChanged(params) {
      this.selectedRows = []
      this.selectedRows = params.selectedRows
    },
    selectAll() {
      this.rows.forEach(item => {
        if (item.vgtSelected) {
          this.$set(item, 'vgtSelected', false)
        } else {
          this.$set(item, 'vgtSelected', true)
        }
      }, this)
    },
    handleEmail(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.validateEmailForm()
    },
    handleEfideData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.validateEfideDataForm()
    },
    handleChangePassword(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.validateChangePasswordForm()
    },
    cleanSendEmailInputs() {
      setTimeout(() => {
        this.subject = ''
        this.description = ''
      }, 1500)
    },
    cleanSendEfideDataInputs() {
      setTimeout(() => {
        this.efideData = null
        this.clientSelected = null
      }, 1500)
    },
    cleanChangePasswordInputs() {
      setTimeout(() => {
        this.cPassword = ''
        this.password = ''
      }, 1200)
    },
    validateChangePasswordForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.sendChangePassword()
        }
      })
    },
    validateEmailForm() {
      this.$refs.emailForm.validate().then(success => {
        if (success) {
          this.sendEmail()
        }
      })
    },
    validateEfideDataForm() {
      this.$refs.efideDataForm.validate().then(success => {
        if (success) {
          this.sendEfideData()
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep .card .card-body {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}

</style>
